import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

export interface Props {
  slides: SlideProps[]
}

export const HeroAdv = memo(function HeroAdv({ slides }: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [opacities, setOpacities] = useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    slides: slides.length,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      slides.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Spinner />

      <Slider ref={sliderRef}>
        {uniq(slides).map((item, index) => (
          <Slide
            key={index}
            isActive={currentSlide === index ? true : false}
            style={
              index !== 0
                ? {
                    opacity: opacities[index],
                  }
                : { opacity: 1 }
            }
            {...item}
          />
        ))}
      </Slider>

      {slides.length > 1 ? (
        <Arrows row space="between">
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 2;
  }
  &:before {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    top: 0;
    opacity: 0.2;
  }
  &:after {
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    bottom: 0;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  right: 11.111vw;
  bottom: 8.125rem;
  z-index: 4;

  > div {
    &:nth-of-type(2) {
      margin-left: 1rem;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
