import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Icon } from './Icon'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const AdvancedIntro = memo(function AdvancedIntro({
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper dial={4} row stretch wrap>
        <LeftSide>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </LeftSide>

        <RightSide dial={2}>
          <Icon />
        </RightSide>
      </Wrapper>

      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 17.75rem;
  position: relative;

  @media (max-width: 1199px) {
    padding-bottom: 0;
  }
`

const Wrapper = styled(FlexBox)`
  z-index: 2;
`

const LeftSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 9.375rem 10.417vw;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 5rem 1.5rem;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  max-width: 16.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  padding-top: 8.5rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Background = styled.div`
  width: 100%;
  height: calc(100% - 21.8125rem);
  position: absolute;
  bottom: 0;
  left: 0;
  &:after {
    content: '';
    width: 100%;
    height: 7.5rem;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
