import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  map?: ImageProps
  title?: string
}

export const Location = memo(function Location({
  cta,
  description,
  image,
  label,
  map,
  title,
}: Props) {
  return (
    <Container>
      <Head>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA variant="gray" {...cta} />
          </FadeInUp>
        ) : null}
      </Head>
      <Wrapper row stretch wrap>
        <LeftSide>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </LeftSide>

        <RightSide>
          {map ? (
            <LazyLoadComponent>
              <Image {...map} />
            </LazyLoadComponent>
          ) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: url('/pattern.webp') no-repeat center 12.5rem
    ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-top: 19.625rem;
  padding-bottom: 20.8125rem;
  position: relative;

  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    padding-top: 6.25rem;
    padding-bottom: 0;
  }
`

const Head = styled.div`
  max-width: 49rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin: auto;
  padding: 5.0625rem 7.778vw;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (max-width: 1199px) {
    max-width: none;
    order: 2;
    padding: 2.5rem 1.5rem 3.75rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;
`

const CTA = styled(Button)`
  margin-top: 3.125rem;
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  > div {
    width: 50%;
    height: 77.8vh;
    max-height: 43.75rem;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    position: relative;
    bottom: auto;
    left: auto;

    > div {
      height: 15.625rem;
    }
  }
`

const LeftSide = styled.div``

const RightSide = styled.div``
