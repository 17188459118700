import styled from '@emotion/styled'
import React, { memo } from 'react'

export const Icon = memo(function Icon() {
  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="159.479"
      height="426.667"
      viewBox="0 0 159.479 426.667"
    >
      <path
        d="M-2346.536,454.3a221.089,221.089,0,0,1-63.794-30.573,224.077,224.077,0,0,1-27.162-22.22,224.316,224.316,0,0,1-23.338-26.178,221.441,221.441,0,0,1-33.215-62.245A221.959,221.959,0,0,1-2506,240.97a221.962,221.962,0,0,1,11.955-72.115,221.443,221.443,0,0,1,33.215-62.245,224.3,224.3,0,0,1,23.338-26.179,224.083,224.083,0,0,1,27.162-22.22,221.081,221.081,0,0,1,63.809-30.577c-15.019,6.7-29.18,16.986-42.09,30.577-12.727,13.4-23.934,29.682-33.311,48.4a273.8,273.8,0,0,0-21.909,62.245,327.622,327.622,0,0,0-7.886,72.115,327.619,327.619,0,0,0,7.886,72.115,273.794,273.794,0,0,0,21.909,62.244c9.377,18.717,20.584,35,33.311,48.4,12.9,13.586,27.061,23.872,42.076,30.571Z"
        transform="translate(2506 -27.634)"
      />
    </Container>
  )
})

const Container = styled.svg`
  width: auto;
  height: 26.625rem;

  path {
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`
