import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  languageCode: string
  title?: string
  URL?: string
}

export const Card = memo(function Card({
  image,
  languageCode,
  title,
  URL,
}: Props) {
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  const DOM = (
    <>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper className="wrapper" dial={4} row wrap>
        {title ? <Title>{title}</Title> : null}

        <CTA dial={4} row>
          {useVocabularyData('discover', languageCode)}
        </CTA>
      </Wrapper>
    </>
  )

  return (
    <>
      {externalURL ? (
        <External href={URL} rel="noopener" target="_blank">
          {DOM}
        </External>
      ) : (
        <Anchor to={URL || ''}>{DOM}</Anchor>
      )}
    </>
  )
})

const Style = css`
  display: block;
  height: 60vh;
  max-height: 33.75rem;
  background: ${theme.colors.variants.neutralLight5};
  overflow: hidden;
  position: relative;
  text-align: left;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }

    .wrapper {
      right: 5.833vw;
      left: 5.833vw;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1199px) {
    height: 20rem;
  }
`

const External = styled.a`
  ${Style}
`

const Anchor = styled(Link)`
  ${Style}
`

const Wrapper = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  position: absolute;
  right: 6.528vw;
  bottom: 5.1875rem;
  left: 6.528vw;
  transition: 0.3s ease-in-out;
  z-index: 2;

  @media (max-width: 1199px) {
    display: block;
    right: 1.5rem;
    bottom: 2.5rem;
    left: 1.5rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2.0625rem;

  @media (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

const CTA = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0469rem;
  line-height: 1.1875rem;
  flex: 1;
  &:before {
    content: '';
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
    flex: 1;
    margin: 0 1.875rem;
  }

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
    &:before {
      display: none;
    }
  }
`
