import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  claim?: string
  image?: ImageProps
  isActive?: boolean
  payoff?: string
  style?: any
}

export const Slide = memo(function Slide({
  claim,
  image,
  isActive,
  payoff,
  style,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <Container className={isActive ? 'active' : undefined} style={style}>
      {image ? <Image {...image} /> : null}

      <Wrapper className={isActive ? 'active' : undefined}>
        {claim ? <Claim>{claim}</Claim> : null}

        {payoff ? <Payoff>{payoff}</Payoff> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.8s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  opacity: 0;
  position: absolute;
  bottom: 8.125rem;
  right: 1.5rem;
  left: 11.458vw;
  transform: translateY(3.75rem);
  transition: 0.4s ease-in-out;
  z-index: 3;
  &.active {
    opacity: 1;
    transform: none;
    transition: 0.6s 1s ease-in-out;
  }

  @media (max-width: 1199px) {
    right: 1.5rem;
    left: 1.5rem;
    text-align: center;
  }
`

const Payoff = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Claim = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`
