import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  images?: ImageProps[]
}

export const SocialWall = memo(function SocialWall({ images }: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    slides: {
      perView: 3.3,
      spacing: 60,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          origin: 'center',
          perView: 1.15,
          spacing: 12,
        },
      },
    },
  })

  return (
    <Container>
      <Wrapper dial={4} row space="between" stretch wrap>
        <LeftSide>
          <Hashtag dial={4} row>
            #hotelkennedyjesolo
            <Wave />
          </Hashtag>

          {images.length > 2 ? (
            <Arrows>
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                variant="full"
              />
              <Arrow
                disabled={currentSlide === 0}
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                variant="full"
              />
            </Arrows>
          ) : null}
        </LeftSide>

        <RightSide>
          <Slider ref={sliderRef}>
            {images.map((item, index) => (
              <Slide key={index} className="keen-slider__slide">
                <LazyLoadComponent>
                  <Image {...item} />
                </LazyLoadComponent>
              </Slide>
            ))}
          </Slider>
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 9.375rem 0 9.375rem 6.111vw;

  @media (max-width: 1199px) {
    padding: 2.5rem 0 6.25rem;
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled.div`
  width: 4rem;
  position: relative;

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Hashtag = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  transform: rotate(-90deg);
  position: absolute;
  top: 6.875rem;
  left: -6.875rem;

  svg {
    width: 4.25rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-left: 1.25rem;
  }

  @media (max-width: 1199px) {
    flex-direction: column-reverse;
    position: relative;
    top: auto;
    left: auto;
    transform: none;

    svg {
      margin-left: 0;
    }
  }
`

const Arrows = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  > div {
    &:last-of-type {
      margin-top: 0.75rem;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const RightSide = styled.div`
  width: calc(100% - 7.75rem);

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 2.5rem;
  }
`

const Slider = styled.div`
  display: flex;
  overflow: hidden;
`

const Slide = styled.div`
  height: 48.9vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 20rem;
  }
`
