import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Wave } from 'app/components/Icons'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Card, Props as CardProps } from './Card'

export interface Props {
  cards: CardProps[]
  cta?: ButtonProps
  image?: ImageProps
  label?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  cards,
  cta,
  image,
  label,
  title,
}: Props) {
  if (!cards) {
    return null
  }

  const [_currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: cards.length > 2 ? true : false,
    slides: {
      origin: 'center',
      perView: 1.35,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          origin: 'center',
          perView: 1.15,
        },
      },
    },
  })

  return (
    <Container>
      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}

      <Head>
        <Wave />

        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </Head>

      <Wrapper>
        <Slider ref={sliderRef}>
          {cards.map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Card {...item} />
            </Slide>
          ))}
        </Slider>

        {cards.length > 2 ? (
          <Arrows>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              variant="full"
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              variant="full"
            />
          </Arrows>
        ) : null}
      </Wrapper>

      {cta ? (
        <FadeInUp>
          <CTA variant="invert" {...cta} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  text-align: center;
`

const Background = styled.div`
  width: 100%;
  height: 55.6vh;
  max-height: 31.25rem;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 3.75rem 1.875rem 5rem;

  svg {
    width: 7.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
  }

  @media (max-width: 1199px) {
    padding: 2.5rem 1.5rem 3.75rem;

    svg {
      width: 5rem;
    }
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Slider = styled.div`
  display: flex;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }
`

const Slide = styled.div`
  padding: 0 6.667vw;

  @media (max-width: 1199px) {
    padding: 0 0.375rem;
  }
`

const Arrows = styled.div`
  > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:first-of-type {
      left: 10.8vw;
    }
    &:last-of-type {
      right: 10.8vw;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`
